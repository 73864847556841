import { Link } from "gatsby";
import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => {
  React.useEffect(() => {
    window.feather.replace();
  }, []);
    return (
      <>
      <div class="back-to-home rounded d-none d-sm-block">
              <Link to="/" className="btn btn-icon btn-soft-primary"><i data-feather="home" class="icons"></i></Link>
          </div>

          
          <section class="bg-home d-flex align-items-center">
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-lg-8 col-md-12 text-center">
                          <img src="images/404.svg" class="img-fluid" alt=""/>
                          <div class="text-uppercase mt-4 display-3">Oh ! no</div>
                          <div class="text-capitalize text-dark mb-4 error-page">Page Not Found</div>
                          <p class="text-muted para-desc mx-auto">Sorry, we couldn't find the page you were looking for.</p>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-md-12 text-center">  
                          <Link to="/" className="btn btn-outline-primary mt-4">Go Back</Link>
                          <Link to="/" className="btn btn-primary mt-4 ms-2">Go To Home</Link>
                      </div>
                  </div>
              </div>
          </section>
      </>
  );
}

export default NotFoundPage
